import {
  defineComponent,
  DefineComponent,
  PropType,
  ref,
  computed,
  watch,
  onBeforeMount,
} from 'vue'
import ListFilter from '@/components/ListFilter/ListFilter.vue'
import { useClientsStore } from '@/store/client'
import { useLawsuitStore } from '@/store/lawsuite'
import { useLawyersStore } from '@/store/lawyers'
import { useTariffsStore } from '@/store/tariffs'
import clickOutside from '@/directives/clickOutside'
import { ListType, SearchFiltersListPanel } from '@/types/filters'

export default defineComponent({
  name: 'ListFilterSelection',
  components: {
    ListFilter,
  },
  directives: {
    clickOutside,
  },
  props: {
    data: Object as PropType<ListType>,
    selectedFilters: Array as PropType<SearchFiltersListPanel[]>,
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const isModalVisible = ref(false)
    const selectedValues = ref<number[]>([])
    const valuesForDisplaying = ref<any[]>([])
    const modal = ref<DefineComponent<typeof ListFilter> | null>(null)
    const selection = ref<HTMLElement | null>(null)
    const initialList = ref<{ id: number }[] | [] | null>(null)
    const isLoading = ref<boolean>(true)
    const clientsStore = useClientsStore()
    const lawsuitStore = useLawsuitStore()
    const lawyersStore = useLawyersStore()
    const tariffsStore = useTariffsStore()

    const clientsListOptions = computed(() => {
      return clientsStore.notPaginatedClients?.map((client) => ({
        ...client,
        label: client.name,
      }))
    })
    const lawsuitListOptions = computed(() => {
      return lawsuitStore.lawsuitData.map((lawsuit) => ({
        ...lawsuit,
        label: `${lawsuit?.lawsuitCategory.name} ${lawsuit?.opponent}`,
      }))
    })
    const tariffListOptions = computed(() => tariffsStore.selectTariffs)
    const lawyerListOptions = computed(() => {
      return lawyersStore.selectLawyers?.map((lawyer) => ({
        ...lawyer,
        label: `${lawyer.lastname ?? ''} ${lawyer.name ?? ''} ${lawyer.surname ?? ''}`,
      }))
    })

    const listOptions = computed(() => {
      const listObj = {
        customerIds: clientsListOptions.value,
        lawsuitIds: lawsuitListOptions.value,
        tariffIds: tariffListOptions.value,
        userIds: lawyerListOptions.value,
      }
      if (props.data?.purpose) {
        return listObj[props.data?.purpose as keyof typeof listObj]
      }
      return []
    })

    watch(
      () => props.selectedFilters,
      (newVal) => {
        if (newVal) {
          const selectedRadioFilter = newVal?.find(
            (filter) => filter.parameter === purpose,
          )
          if (!selectedRadioFilter) {
            selectedValues.value = []
            valuesForDisplaying.value = []
          }
        }
      },
    )

    const purpose = props.data?.purpose as keyof typeof labels

    const labels = {
      customerIds: 'Клиенты',
      lawsuitIds: 'Дело',
      tariffIds: 'Тариф',
      userIds: 'Адвокаты',
    }

    const obj = {
      labelText: labels[purpose],
      text: '',
      value: {},
    }

    const onSelectionFieldsClick = () => {
      isModalVisible.value = !isModalVisible.value
    }

    const onSelectionClose = () => {
      isModalVisible.value = false
    }

    const onRemoveDisplayedOptionBtnClick = (id: number) => {
      valuesForDisplaying.value = valuesForDisplaying.value.filter(
        (item) => item.id !== id,
      )
      selectedValues.value = selectedValues.value.filter((item) => item !== id)

      obj.text = selectedValues.value.length
        ? String(selectedValues.value.length)
        : ''
      obj.value = {
        [purpose]: selectedValues.value,
      }
      emit('update:value', obj)
    }

    const filterSelectedItems = (array: { id: number }[]) =>
      array.filter((item) => selectedValues.value.includes(item.id))

    const onGetValues = async (data: number[]) => {
      onSelectionClose()
      if (!data.length) {
        return
      }

      let text = ''
      if (purpose === 'customerIds' || purpose === 'userIds') {
        text = String(data.length)
      }
      if (purpose === 'lawsuitIds') {
        const selectedLawsuit = lawsuitListOptions.value?.find(
          (item) => item.id === data[0],
        )
        text = `${selectedLawsuit?.lawsuitCategory.name} ${selectedLawsuit?.opponent}`
      }
      if (purpose === 'tariffIds') {
        const selectedTariff = tariffListOptions.value?.find(
          (item) => item.id === data[0],
        )
        if (selectedTariff) {
          text = selectedTariff?.label
        }
      }

      obj.text = text
      obj.value = {
        [purpose]: data,
      }
      selectedValues.value = data

      let filteredOptions
      if (
        (purpose === 'customerIds' || purpose === 'userIds') &&
        initialList.value
      ) {
        filteredOptions = filterSelectedItems(initialList.value)
      }
      if (purpose === 'lawsuitIds' && listOptions.value) {
        filteredOptions = filterSelectedItems(listOptions.value)
      }
      if (purpose === 'tariffIds' && listOptions.value) {
        filteredOptions = filterSelectedItems(listOptions.value)
      }

      if (filteredOptions) {
        valuesForDisplaying.value = filteredOptions
      }
      emit('update:value', obj)
    }

    const getModalPosition = computed(() => {
      const modalHeight = modal.value?.wrapper?.offsetHeight
      const parentRect = selection.value?.getBoundingClientRect()
      const viewportHeight = window.innerHeight

      if (parentRect?.bottom + modalHeight > viewportHeight) {
        return { top: 'auto', bottom: 'calc(100% + 5px)' }
      } else {
        return { top: 'calc(100% + 5px)', bottom: 'auto' }
      }
    })

    onBeforeMount(async () => {
      if (props.data?.purpose === 'customerIds') {
        await clientsStore.getClients({ isPaginated: 0 })
        if (clientsStore.notPaginatedClients) {
          initialList.value = clientsStore.notPaginatedClients
        }
      }
      if (props.data?.purpose === 'lawsuitIds') {
        await lawsuitStore.getLawsuitList()
      }
      if (props.data?.purpose === 'lawsuitCategoryIds') {
        await lawsuitStore.getLawsuitCategoriesList()
      }
      if (props.data?.purpose === 'userIds') {
        await lawyersStore.getLawyers({ isPaginated: 0 })
        if (lawyerListOptions.value) {
          initialList.value = lawyerListOptions.value
        }
      }
      if (props.data?.purpose === 'tariffIds') {
        await tariffsStore.getAllTariffs({ isPaginated: 0 })
      }
    })

    return {
      isModalVisible,
      selectedValues,
      listOptions,
      isLoading,
      valuesForDisplaying,
      modal,
      selection,
      purpose,
      getModalPosition,
      onSelectionFieldsClick,
      onSelectionClose,
      onRemoveDisplayedOptionBtnClick,
      onGetValues,
    }
  },
})
