<template>
  <div>
    <label class="date__label" v-if="label">{{ label }}</label>
    <VueDatePicker
      v-bind="$attrs"
      :class="['picker', { onlyPast: onlyPast }]"
      v-model="date"
      :min-date="minDate ? minDate : null"
      :max-date="maxDate ? maxDate : null"
      id="datePicker"
      :format="format"
      :text-input="textInputOptions"
      locale="ru-RU"
      :space-confirm="true"
      position="center"
      auto-apply
      no-today
      required
      :ui="uiOptions"
      :day-class="getDayClass"
      :enable-time-picker="false"
      :six-weeks="true"
      :placeholder="placeholder"
      :year-range="yearRange"
      :disabled="disabled"
      @input="onDatePickerValueChanged"
    >
      <template #input-icon>
        <SvgIcon icon="calendar" class="picker__icon" />
      </template>
      <template v-if="isCalendar" #day="{ day, date }">
        <template v-if="isTribunal(date)">
          <span
            :class="[{ withColor: isTribunal(date) }]"
            :style="{ background: isTribunal(date), color: 'white' }"
            >{{ day }}</span
          >
        </template>
        <template v-else>
          {{ day }}
        </template>
      </template>
    </VueDatePicker>
  </div>
</template>

<script lang="ts" src="./DateElement.ts"></script>
<style lang="scss" src="./DateElement.scss"></style>
