import {
  defineComponent,
  ref,
  reactive,
  computed,
  watch,
  watchEffect,
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import clickOutside from '@/directives/clickOutside'
import { useUXUIStore } from '@/store/uxui'
import { useClientsStore } from '@/store/client'
import { useMainStore } from '@/store/main'
import { useTasksStore } from '@/store/tasks'
import { useFinanceStore } from '@/store/finance'
import { useLawsuitStore } from '@/store/lawsuite'
import { useTariffsStore } from '@/store/tariffs'
import { useLawyersStore } from '@/store/lawyers'
import { usePaymentStore } from '@/store/payment'
import SearchFilters from '@/components/SearchFilters/SearchFilters.vue'
import SearchButtonsContainer from '@/components/SearchButtonsContainer/SearchButtonsContainer.vue'
import { filters } from '@/config/filters'
import { getFormatDateWithDash } from '@/helpers/dateFormatter'
import { useLockBodyScroll } from '@/composables/useLockBodyScroll'
import { modalsContent } from '@/config/confirmationModals'
import { TaskSearchPayload } from '@/types/tasks'
import { useNotificationStore } from '@/store/notification'
import { adminMenu, userMenu } from '@/config/lawsuitTableHeadConfig'
import { FilterReturnValue, SearchFiltersListPanel } from '@/types/filters'
import { TransactionListGetPayload } from '@/types/transaction'
import { LawyersListGetPayload } from '@/types/lawyers'
import { useEventsStore } from '@/store/events'

export default defineComponent({
  name: 'NavBar',
  components: {
    SearchFilters,
    SearchButtonsContainer,
  },
  directives: {
    clickOutside,
  },
  setup() {
    const uxuiStore = useUXUIStore()
    const router = useRouter()
    const lawsuitStore = useLawsuitStore()
    const clientStore = useClientsStore()
    const notificationStore = useNotificationStore()
    const mainStore = useMainStore()
    const tasksStore = useTasksStore()
    const financeStore = useFinanceStore()
    const lawyersStore = useLawyersStore()
    const paymentsStore = usePaymentStore()
    const tariffsStore = useTariffsStore()
    const eventsStore = useEventsStore()
    const isMobile = computed(() => mainStore.isMobile)
    const isTablet = computed(() => mainStore.isTablet)
    const tab = tasksStore.tab
    const { enableBodyScroll, disableBodyScroll } = useLockBodyScroll()
    const route = useRoute()
    const query = ref('')
    const isSearchOpen = ref(false)
    const currentRoute = computed(() => router.currentRoute.value)
    const areFiltersOpen = ref(isMobile.value || isTablet.value)
    const filterValues = reactive<{ [key: string]: any }>({})
    const filtersSelection = ref<SearchFiltersListPanel[] | []>([])
    const isNotificationListPresent = computed(
      () => notificationStore.notificationList?.length,
    )
    const isCalendar = computed(() => route.name === 'calendar')

    const showSearch = computed(
      () =>
        !['lawsuit-details', 'client-details', 'settings', 'profile'].includes(
          String(route.name),
        ),
    )

    const menuItems = computed(() =>
      uxuiStore.getIsAdmin ? adminMenu : userMenu,
    )

    const navigateToProfileHandler = async () => {
      await router.push('/profile')
    }

    const isAsideCollapsed = computed(() => uxuiStore.asideCollapsed)
    const togglePanelHandler = (): void => {
      uxuiStore.switchAside()
    }

    watch(
      () => currentRoute.value.name,
      async () => {
        await resetSearchFields(false)
      },
    )

    const filterContent = computed(() => {
      if (!currentRoute.value.name || !(currentRoute.value.name in filters)) {
        return null
      }
      const { filtersList, values } = filters[currentRoute.value.name]
      return { filtersList, values }
    })

    watch(
      () => filterContent,
      (newValue) => {
        if (newValue.value && Object.keys(newValue.value.values).length) {
          Object.assign(filterValues, newValue.value.values)
        }
      },
      {
        deep: true,
        immediate: true,
      },
    )

    watch(
      () => filtersSelection.value,
      async () => {
        switch (route.name) {
          case 'clients-table':
            await handleClientsSearch()
            return
          case 'adminHome':
            await handleTariffsSearchSubmit()
            return
          case 'adminLawyers':
            await handleLawyersSearchSubmit()
            return
        }
      },
      { deep: true },
    )

    watchEffect(() => {
      if (isSearchOpen.value) {
        disableBodyScroll()
      } else {
        enableBodyScroll()
      }
    })

    const onRemoveFilterClick = async (index: number, parameter: string) => {
      filtersSelection.value = filtersSelection.value.filter(
        (_, i) => i !== index,
      )
      delete filterValues[parameter]
      if (route.name === 'adminHome') {
        removeSearchParams(tariffsStore, parameter)
      }
      if (route.name === 'adminLawyers') {
        removeSearchParams(lawyersStore, parameter)
      }
      if (route.name === 'calendar') {
        removeSearchParams(lawyersStore, parameter)
      }
      await submitHandlers(false)
      if (route.name === 'home') {
        await lawsuitStore.deleteFilter(parameter)
      }
    }

    const saveSearchParams = (
      store:
        | typeof tasksStore
        | typeof financeStore
        | typeof lawyersStore
        | typeof paymentsStore,
      params:
        | TaskSearchPayload
        | TransactionListGetPayload
        | LawyersListGetPayload,
    ) => {
      store.searchParams = params
    }

    const removeSearchParams = (
      store: typeof tariffsStore | typeof lawyersStore | typeof paymentsStore,
      parameter: string,
    ) => {
      if (store.searchParams) {
        delete store.searchParams[parameter as keyof typeof store.searchParams]
      }
    }

    const toggleSearchOnMobile = () => {
      isSearchOpen.value = !isSearchOpen.value
      areFiltersOpen.value = true
    }

    const closeSearchOnMobile = () => {
      isSearchOpen.value = false
    }

    const filtersOpenHandler = () => {
      areFiltersOpen.value = true
    }

    const filtersCloseHandler = () => {
      if (!isMobile.value && !isTablet.value) {
        areFiltersOpen.value = false
      }
    }

    const resetSearchFields = async (withRequiest: boolean) => {
      query.value = ''
      Object.keys(filterValues).forEach((key) => {
        delete filterValues[key]
      })
      filtersSelection.value = []
      if (withRequiest) {
        await submitHandlers(false)
      }
    }

    const onValuesChange = (values: FilterReturnValue) => {
      const filterValue = values.value
      const filterKey = Object.keys(values.value)[0]

      filterValues[filterKey] = filterValue[filterKey]

      // Всё необходимое для лэйбла с текстом категории и значением

      const newFilter: { title: string; parameter: string; value: string } = {
        title: values.labelText,
        parameter: filterKey,
        value: '',
      }

      const index = filtersSelection.value.findIndex(
        (filter) => filter.title === values.labelText,
      )

      if (values.text) {
        newFilter.value = values.text
      }

      if (index < 0) {
        filtersSelection.value = [...filtersSelection.value, newFilter]
        return
      }

      // Если текст value есть (во всех, кроме select с allowEmpty) - заменяем
      if (newFilter.value) {
        filtersSelection.value[index] = newFilter
      } else {
        filtersSelection.value = filtersSelection.value.filter(
          (item) => item.title !== newFilter.title,
        )
      }
    }

    const handleClientsSearch = async () => {
      if (!isMobile.value && !isTablet.value) {
        const params = { name: query.value, ...filterValues }
        await clientStore.getClients(params)
      }
    }

    const handleSearchClientsFormSubmit = async (shouldClose?: boolean) => {
      if (!isMobile.value && !isTablet.value) {
        areFiltersOpen.value = false
      }
      const params = { name: query.value, ...filterValues, page: 1 }

      try {
        await clientStore.getClients(params)
        if (isMobile.value || isTablet.value || shouldClose) {
          closeSearchOnMobile()
        }
      } catch (error) {
        console.log(error)
      }
    }

    const handlerSearchHomeFormSubmit = async (isClose: boolean = true) => {
      await lawsuitStore.applyFilter({ ...filterValues, phrase: query.value })
      if (isClose) {
        areFiltersOpen.value = false
        isSearchOpen.value = false
      }
    }

    const handlerSearchTasksFormSubmit = async (shouldClose?: boolean) => {
      const body = {
        customerIds: filterValues.customerIds,
        lawsuitIds: filterValues.lawsuitIds,
        taskTagIds: filterValues.taskTagIds,
        status: filterValues.status,
        since:
          filterValues.deadline && filterValues.deadline.from
            ? getFormatDateWithDash(filterValues?.deadline.from)
            : null,
        till:
          filterValues.deadline && filterValues.deadline.to
            ? getFormatDateWithDash(filterValues?.deadline.to)
            : null,
        isBillable: filterValues.isBillable,
        theme: query.value,
        page: 1,
        ...tab?.searchParams,
      }

      try {
        await tasksStore.getTasks(body)
        saveSearchParams(tasksStore, body)
        if (!isMobile.value && !isTablet.value) {
          areFiltersOpen.value = false
          return
        }
        if (shouldClose) {
          closeSearchOnMobile()
        }
      } catch (error) {
        console.log(error)
      }
    }

    const handleSearchTransactionsFormSubmit = async (
      shouldClose?: boolean,
    ) => {
      const body = {
        customerIds: filterValues.customerIds,
        type: filterValues.type,
        since:
          filterValues.deadline && filterValues.deadline.from
            ? getFormatDateWithDash(filterValues.deadline.from)
            : null,
        till:
          filterValues.deadline && filterValues.deadline.to
            ? getFormatDateWithDash(filterValues.deadline.to)
            : null,
        phrase: query.value,
        page: 1,
        itemsPerPage: financeStore.searchParams
          ? financeStore.searchParams.itemsPerPage
          : 10,
      }

      try {
        await financeStore.getTransactions(body)
        saveSearchParams(financeStore, body)
        if (!isMobile.value && !isTablet.value) {
          areFiltersOpen.value = false
          return
        }
        if (shouldClose) {
          closeSearchOnMobile()
        }
      } catch (error) {
        console.log(error)
      }
    }

    const handleTariffsSearchSubmit = async () => {
      if (!isMobile.value && !isTablet.value) {
        const params = { queryString: query.value, page: 1, ...filterValues }
        await tariffsStore.getAllTariffs(params)
      }
    }

    const handleSearchTariffsFormSubmit = async (isClose: boolean = true) => {
      if (!isMobile.value && !isTablet.value) {
        areFiltersOpen.value = false
      }
      const params = { queryString: query.value, page: 1, ...filterValues }

      try {
        await tariffsStore.getAllTariffs(params)
        if (isMobile.value || isTablet.value || isClose) {
          closeSearchOnMobile()
        }
      } catch (error) {
        console.log(error)
      }
    }

    const handleLawyersSearchSubmit = async () => {
      if (!isMobile.value && !isTablet.value) {
        const params = { queryString: query.value, page: 1, ...filterValues }
        await lawyersStore.getLawyers(params)
      }
    }

    const handleSearchCalendarFormSubmit = async () => {
      areFiltersOpen.value = false
      await eventsStore.getAllEvents({ ...filterValues, phrase: query.value })
    }

    const handleSearchLawyersFormSubmit = async (isClose: boolean = true) => {
      if (!isMobile.value && !isTablet.value) {
        areFiltersOpen.value = false
      }
      const params = { queryString: query.value, page: 1, ...filterValues }

      try {
        await lawyersStore.getLawyers(params)
        //saveSearchParams(lawyersStore, params as LawyersListGetPayload)
        if (isMobile.value || isTablet.value || isClose) {
          closeSearchOnMobile()
        }
      } catch (error) {
        console.log(error)
      }
    }

    const handleSearchPaymentsFormSubmit = async (isClose: boolean = true) => {
      if (!isMobile.value && !isTablet.value) {
        areFiltersOpen.value = false
      }

      const body = {
        tariffIds: filterValues.tariffIds,
        userIds: filterValues.userIds,
        since:
          filterValues.deadline && filterValues.deadline.from
            ? filterValues.deadline.from
            : null,
        till:
          filterValues.deadline && filterValues.deadline.to
            ? filterValues.deadline.to
            : null,
        queryString: query.value,
        page: 1,
      }

      try {
        await paymentsStore.getPayments(body)
        saveSearchParams(paymentsStore, body)
        if (isMobile.value || isTablet.value || isClose) {
          closeSearchOnMobile()
        }
      } catch (error) {
        console.log(error)
      }
    }

    const submitHandlers = async (isClose: boolean = true) => {
      switch (route.name) {
        case 'finance':
          await handleSearchTransactionsFormSubmit(isClose)
          return
        case 'clients-table':
          await handleSearchClientsFormSubmit(isClose)
          return
        case 'tasks':
          await handlerSearchTasksFormSubmit(isClose)
          return
        case 'home':
          await handlerSearchHomeFormSubmit(isClose)
          return
        case 'adminHome':
          await handleSearchTariffsFormSubmit(isClose)
          return
        case 'adminLawyers':
          await handleSearchLawyersFormSubmit(isClose)
          return
        case 'adminFinance':
          await handleSearchPaymentsFormSubmit(isClose)
          return
        case 'calendar':
          await handleSearchCalendarFormSubmit()
          return
        default:
          return
      }
    }

    const logoutClick = () => {
      uxuiStore.setModalName('ConfirmationModal')
      uxuiStore.setModalContent(modalsContent['logout'], 100)
    }

    const toggleNotificationPanelHandler = () => {
      uxuiStore.switchNotificationPanel()
    }

    watch(
      () => tasksStore.tab,
      () => {
        if (filterValues['status']) {
          filtersSelection.value = filtersSelection.value.filter(
            (item) => item.parameter !== 'status',
          )
        }
      },
      { deep: true },
    )

    return {
      query,
      isSearchOpen,
      areFiltersOpen,
      currentRoute,
      isMobile,
      isTablet,
      filterContent,
      filtersSelection,
      onRemoveFilterClick,
      filtersOpenHandler,
      filtersCloseHandler,
      toggleSearchOnMobile,
      closeSearchOnMobile,
      isAsideCollapsed,
      togglePanelHandler,
      uxuiStore,
      onValuesChange,
      submitHandlers,
      resetSearchFields,
      menuItems,
      navigateToProfileHandler,
      logoutClick,
      toggleNotificationPanelHandler,
      showSearch,
      isNotificationListPresent,
      isCalendar,
    }
  },
})
