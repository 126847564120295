import axios from 'axios'
import {
  TariffListGetPayload,
  TariffListGetResponse,
  TariffItemCreatePayload,
  TariffItemEditPayload,
  TariffItemCreateEditResponse,
  TariffItemGetDeletePayload,
  TariffItemGetResponse,
  TariffItemDeleteResponse,
  TariffItemStatusChangePayload,
  TariffItemStatusChangeResponse,
  AppStatResponse,
} from '@/types/tariff'

export const getTariffListApiCall = async (
  abortController?: AbortController,
  params?: Partial<TariffListGetPayload>,
): Promise<TariffListGetResponse> => {
  const { data } = await axios.get('/v1/admin/tariff', {
    params: { ...params },
    signal: abortController?.signal,
  })
  return data
}

export const getUserTariffsApiCall = async (
  abortController?: AbortController,
  params?: Partial<TariffListGetPayload>,
) => {
  const { data } = await axios.get('/v1/tariff', {
    params: { ...params },
    signal: abortController?.signal,
  })
  return data
}

export const createTariffItemApiCall = async (
  abortController?: AbortController,
  params?: TariffItemCreatePayload,
): Promise<TariffItemCreateEditResponse> => {
  const { data } = await axios.post(
    '/v1/admin/tariff',
    {
      ...params,
    },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const editTariffItemApiCall = async (
  abortController?: AbortController,
  params?: TariffItemEditPayload,
): Promise<TariffItemCreateEditResponse> => {
  const { data } = await axios.put(
    `/v1/admin/tariff/${params?.id}`,
    {
      ...params?.data,
    },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const getTariffItemApiCall = async (
  abortController?: AbortController,
  params?: TariffItemGetDeletePayload,
): Promise<TariffItemGetResponse> => {
  const { data } = await axios.get(`/v1/admin/tariff/${params?.id}`, {
    signal: abortController?.signal,
  })
  return data
}

export const changeTariffItemStatusApiCall = async (
  abortController?: AbortController,
  params?: TariffItemStatusChangePayload,
): Promise<TariffItemStatusChangeResponse> => {
  const { data } = await axios.patch(
    `/v1/admin/tariff/${params?.id}/update-status`,
    {
      ...params?.data,
    },
    {
      signal: abortController?.signal,
    },
  )
  return data
}

export const deleteTariffItemApiCall = async (
  abortController?: AbortController,
  params?: TariffItemGetDeletePayload,
): Promise<TariffItemDeleteResponse> => {
  const { data } = await axios.delete(`/v1/admin/tariff/${params?.id}`, {
    signal: abortController?.signal,
  })
  return data
}

export const getApplicationDataApiCall = async (
  abortController?: AbortController,
): Promise<AppStatResponse> => {
  const { data } = await axios.get('/v1/admin/application', {
    signal: abortController?.signal,
  })
  return data
}
