import { defineStore } from 'pinia'
import { useApiCall } from '@/composables/useApiCall'
import {
  getLawyersListApiCall,
  getLawyerItemApiCall,
  changeLawyerItemTariffApiCall,
  activateLawyerItemApiCall,
  deleteLawyerItemApiCall,
  getStatisticsApiCall,
} from '@/api/lawyers'
import { RootState, RootGetters, RootActions } from './types'
import { UserData } from '@/types/user'
import {
  LawyersListGetPayload,
  LawyersListGetResponse,
  LawyerItemChangeTariffPayload,
  LawyerItemGetDeletePayload,
  LawyerItemGetDeleteResponse,
  LawyersStatistics,
} from '@/types/lawyers'
import { DefaultError } from '@/types/httpError'

export const useLawyersStore = defineStore<
  string,
  RootState,
  RootGetters,
  RootActions
>('lawyers', {
  state() {
    return {
      allLawyers: null,
      selectLawyers: null,
      currentPage: 1,
      lastPage: 1,
      searchParams: null,
      statistics: null,
      isPanelOpen: false,
      selectedLawyer: null,
      error: null,
    }
  },
  actions: {
    async getLawyers(params: Partial<LawyersListGetPayload>, isLazy?: boolean) {
      this.error = null
      const {
        data: lawyersListData,
        executeApiCall: getLawyers,
        error: lawyersListError,
      } = useApiCall<
        LawyersListGetResponse,
        DefaultError,
        Partial<LawyersListGetPayload>
      >(getLawyersListApiCall, true)

      const paramsWithSotring = Object.keys(params).some((key) =>
        key.endsWith('sort'),
      )
      if (paramsWithSotring && this.searchParams) {
        Object.keys(this.searchParams).forEach((key: string) => {
          if (key.endsWith('sort') && this.searchParams) {
            delete this.searchParams[key as keyof typeof this.searchParams]
          }
        })
      }

      this.searchParams = { ...this.searchParams, ...params }

      try {
        await getLawyers(this.searchParams)
        if (lawyersListData.value) {
          if (params && 'isPaginated' in params) {
            this.selectLawyers = lawyersListData.value.data
            return
          }

          const { lastPage, currentPage } = lawyersListData.value.meta
          this.currentPage = currentPage
          this.lastPage = lastPage
          if (isLazy) {
            if (this.currentPage === 1) {
              this.allLawyers = lawyersListData.value.data
              return
            }
            if (Array.isArray(this.allLawyers)) {
              this.allLawyers = [
                ...this.allLawyers,
                ...lawyersListData.value.data,
              ]
            }
          } else {
            this.allLawyers = lawyersListData.value.data
          }
        }
      } catch (error) {
        if (lawyersListError.value?.data.error) {
          this.error = lawyersListError.value?.data.error
        }
      }
    },
    async loadMoreLawyers() {
      this.error = null
      if (this.currentPage < this.lastPage) {
        this.currentPage++
        let newParams = { page: this.currentPage }
        if (this.searchParams) {
          newParams = { ...this.searchParams, ...newParams }
        }
        await this.getLawyers(newParams, true)
      }
    },
    async getLawyer(params: LawyerItemGetDeletePayload) {
      this.error = null

      const {
        data: lawyerItemData,
        executeApiCall: getLawyer,
        error: lawyerItemError,
      } = useApiCall<
        LawyerItemGetDeleteResponse<UserData>,
        DefaultError,
        LawyerItemGetDeletePayload
      >(getLawyerItemApiCall, true)

      try {
        await getLawyer(params)
      } catch (error) {
        if (lawyerItemError.value?.data.error) {
          this.error = lawyerItemError.value?.data.error
        }
      }

      return lawyerItemData.value ? lawyerItemData.value.data : null
    },
    async changeLawyerTariff(params: LawyerItemChangeTariffPayload) {
      this.error = null

      const {
        data: changedLawyerItemData,
        executeApiCall: changeLawyerTariff,
        error: changedLawyerItemError,
      } = useApiCall<
        LawyerItemGetDeleteResponse<''>,
        DefaultError,
        LawyerItemChangeTariffPayload
      >(changeLawyerItemTariffApiCall, true)

      try {
        await changeLawyerTariff(params)
        if (changedLawyerItemData.value?.success === 1) {
          const lawyer = await this.getLawyer({ id: params.id })
          if (lawyer) {
            this.replaceLawyer(params.id, lawyer)
          }
        }
      } catch (error) {
        if (changedLawyerItemError.value?.status === 404) {
          this.error = {
            data: {
              message: '',
            },
          }
          this.error.data.message =
            'Данный пользователь удален или заблокирован'
          return
        }
        if (changedLawyerItemError.value?.data.error.message) {
          this.error = changedLawyerItemError.value?.data.error.message
        }
      }
    },
    async blockLawyer(params: LawyerItemGetDeletePayload) {
      this.error = null

      const { executeApiCall: blockUser, error: blockUserError } = useApiCall<
        LawyerItemGetDeleteResponse<''>,
        DefaultError,
        LawyerItemGetDeletePayload
      >(deleteLawyerItemApiCall, true)

      try {
        await blockUser(params)
        const lawyer = await this.getLawyer({ id: params.id })
        if (lawyer) {
          this.replaceLawyer(params.id, lawyer)
        }
      } catch (error) {
        if (blockUserError.value?.data.error) {
          this.error = blockUserError.value?.data.error
        }
      }
    },
    async restoreLawyer(params: LawyerItemGetDeletePayload) {
      this.error = null

      const { executeApiCall: restoreUser, error: restoreUserError } =
        useApiCall<
          LawyerItemGetDeleteResponse<''>,
          DefaultError,
          LawyerItemGetDeletePayload
        >(activateLawyerItemApiCall, true)

      try {
        await restoreUser(params)
        const lawyer = await this.getLawyer({ id: params.id })
        if (lawyer) {
          this.replaceLawyer(params.id, lawyer)
        }
      } catch (error) {
        if (restoreUserError.value?.data.error) {
          this.error = restoreUserError.value?.data.error
        }
      }
    },
    replaceLawyer(id: number, newBody: UserData) {
      const index = this.allLawyers?.findIndex((item) => item.id === id)
      if (this.allLawyers && index !== undefined) {
        this.allLawyers[index] = {
          ...this.allLawyers[index],
          ...newBody,
        }
      }
    },
    async getStatistics() {
      this.error = null

      const {
        data: statisticsData,
        executeApiCall: getStatistics,
        error: statisticsError,
      } = useApiCall<
        LawyerItemGetDeleteResponse<LawyersStatistics>,
        DefaultError
      >(getStatisticsApiCall, true)

      try {
        await getStatistics()
        if (statisticsData.value) {
          this.statistics = statisticsData.value.data
        }
      } catch (error) {
        if (statisticsError.value?.data.error) {
          this.error = statisticsError.value?.data.error
        }
      }
    },
    openPanel() {
      this.isPanelOpen = true
    },
    closePanel() {
      this.isPanelOpen = false
    },
  },
})
