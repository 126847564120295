import { computed, defineComponent } from 'vue'
import { useCalendarStore } from '@/store/calendar'
import { ViewItem } from '@/types/calendar'
import { useUXUIStore } from '@/store/uxui'
import { useMainStore } from '@/store/main'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'CalendarViewsMenu',
  setup() {
    const uxuiStore = useUXUIStore()
    const mainStore = useMainStore()
    const calendarStore = useCalendarStore()
    const changeDate = async (type: string) => {
      await calendarStore.changeInitialDate(type)
    }
    const getPeriod = computed(() => {
      if (calendarStore.currentView?.title) {
        switch (calendarStore.currentView.title) {
          case 'year':
            return dayjs(calendarStore.period[0], 'DD.MM.YYYY').format('YYYY')
          case 'month':
            return dayjs(calendarStore.period[0], 'DD.MM.YYYY').format(
              'MMMM YYYY',
            )
          default:
            return calendarStore.period.join('-')
        }
      } else {
        return ''
      }
    })

    const changeView = (view: ViewItem) => {
      calendarStore.changeView(view.value)
    }
    return {
      changeDate,
      changeView,
      calendarStore,
      uxuiStore,
      mainStore,
      getPeriod,
    }
  },
})
