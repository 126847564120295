import { Filters } from '@/types/filters'

export const filters: Filters = {
  'clients-table': {
    filtersList: {
      radio: {
        title: 'По статусам',
        options: [
          { valueToShow: 'Все', valueToPass: null },
          { valueToShow: 'Активный', valueToPass: 1 },
          { valueToShow: 'Неактивный', valueToPass: 0 },
        ],
        component: 'RadioFilter',
        purpose: 'isContractValidityValid',
      },
    },
    values: {
      isContractValidityValid: null,
    },
  },
  tasks: {
    filtersList: {
      radio: {
        title: 'По статусам',
        options: [
          { valueToShow: 'Все', valueToPass: null },
          { valueToShow: 'Запланирована', valueToPass: 'planned' },
          { valueToShow: 'Завершена', valueToPass: 'finished' },
        ],
        component: 'RadioFilter',
        purpose: 'status',
      },
      group: {
        title: 'По содержимому',
        filters: {
          clientsList: {
            title: 'По клиенту',
            component: 'ListFilterSelection',
            placeholder: 'Выберите клиентов',
            modal: {
              component: 'ListFilter',
              title: 'Клиенты',
              multiple: true,
            },
            purpose: 'customerIds',
          },
          lawsuitsList: {
            title: 'По делу',
            component: 'ListFilterSelection',
            placeholder: 'Выберите дело',
            modal: {
              component: 'ListFilter',
              title: 'Дела',
              multiple: false,
            },
            purpose: 'lawsuitIds',
          },
          select: {
            title: 'По Тэгу',
            options: 'tags',
            placeholder: 'Выберите тэги',
            component: 'SelectElement',
            multiple: true,
            closeOnSelect: false,
            purpose: 'taskTagIds',
          },
          radio: {
            title: 'По стоимости',
            options: [
              { valueToShow: 'Все', valueToPass: null },
              { valueToShow: 'Платно', valueToPass: true },
              { valueToShow: 'Бесплатно', valueToPass: false },
            ],
            component: 'RadioFilter',
            purpose: 'isBillable',
          },
        },
        component: 'SearchFiltersGroup',
      },
      dateRange: {
        title: 'По дедлайну',
        from: 'От',
        to: 'До',
        component: 'DateRangeFilter',
        label: 'Дедлайн',
        purpose: 'deadline',
        ownTitle: true,
      },
    },
    values: {
      status: null,
      deadline: {
        from: '',
        to: '',
      },
      taskTagIds: [],
      customerIds: [],
      lawsuitIds: [],
      isBillable: null,
    },
  },
  home: {
    filtersList: {
      group: {
        title: 'По содержимому',
        filters: {
          clientsList: {
            title: 'По клиенту',
            component: 'ListFilterSelection',
            placeholder: 'Выберите клиентов',

            modal: {
              component: 'ListFilter',
              title: 'Клиенты',
              multiple: true,
            },
            purpose: 'customerIds',
          },
          categoriesList: {
            title: 'По категории дела',
            options: 'lawsuitCategories',
            placeholder: 'Выберите дело',
            component: 'SelectElement',
            multiple: true,
            closeOnSelect: false,
            purpose: 'lawsuitCategoryIds',
          },
          select: {
            title: 'По типу события',
            options: 'tags',
            placeholder: 'Выберите тип события',
            component: 'SelectElement',
            multiple: true,
            closeOnSelect: false,
            purpose: 'lawsuitEventCategoryIds',
          },
        },
        component: 'LawsuitFilter',
      },
    },
    values: {
      customerIds: [],
      lawsuitCategoryIds: [],
      lawsuitEventCategoryIds: [],
    },
  },
  finance: {
    filtersList: {
      radio: {
        title: 'По типу',
        options: [
          { valueToShow: 'Все', valueToPass: null },
          { valueToShow: 'Доход', valueToPass: 'debit' },
          { valueToShow: 'Расход', valueToPass: 'credit' },
        ],
        component: 'RadioFilter',
        purpose: 'type',
      },
      group: {
        title: 'По содержимому',
        filters: {
          clientsList: {
            title: 'По клиенту',
            component: 'ListFilterSelection',
            placeholder: 'Выберите клиентов',
            modal: {
              component: 'ListFilter',
              title: 'Клиенты',
              multiple: true,
            },
            purpose: 'customerIds',
          },
          dateRange: {
            title: 'По дедлайну',
            from: 'От',
            to: 'До',
            component: 'DateRangeFilter',
            label: 'Дедлайн',
            purpose: 'deadline',
            ownTitle: true,
          },
        },
        component: 'SearchFiltersGroup',
      },
    },
    values: {
      type: null,
      customerIds: [],
      deadline: {
        from: '',
        to: '',
      },
    },
  },
  adminHome: {
    filtersList: {
      radio: {
        title: 'По статусам',
        options: [
          { valueToShow: 'Все', valueToPass: null },
          { valueToShow: 'Активный', valueToPass: 'active' },
          { valueToShow: 'Архивный', valueToPass: 'archive' },
        ],
        component: 'RadioFilter',
        purpose: 'status',
      },
    },
    values: {
      status: null,
    },
  },
  adminLawyers: {
    filtersList: {
      radio: {
        title: 'По статусам',
        options: [
          { valueToShow: 'Все', valueToPass: null },
          { valueToShow: 'Активный', valueToPass: 1 },
          { valueToShow: 'Неактивный', valueToPass: 0 },
        ],
        component: 'RadioFilter',
        purpose: 'isActive',
      },
    },
    values: {
      isActive: null,
    },
  },
  adminFinance: {
    filtersList: {
      group: {
        title: 'По содержимому',
        filters: {
          tariffsList: {
            title: 'По тарифам',
            component: 'ListFilterSelection',
            placeholder: 'Выберите тариф',
            modal: {
              component: 'ListFilter',
              title: 'Тарифы',
              multiple: false,
            },
            purpose: 'tariffIds',
          },
          lawyersList: {
            title: 'По адвокатам',
            component: 'ListFilterSelection',
            placeholder: 'Выберите адвокатов',
            modal: {
              component: 'ListFilter',
              title: 'Адвокаты',
              multiple: true,
            },
            purpose: 'userIds',
          },
        },
        component: 'SearchFiltersGroup',
      },
      dateRange: {
        title: 'По дате',
        from: 'От',
        to: 'До',
        component: 'DateRangeFilter',
        label: 'Дата',
        purpose: 'date',
        ownTitle: true,
      },
    },
    values: {
      tariffIds: [],
      userIds: [],
      deadline: {
        from: '',
        to: '',
      },
    },
  },
  calendar: {
    filtersList: {
      radio: {
        title: 'По статусам',
        options: [
          { valueToShow: 'Все', valueToPass: null },
          { valueToShow: 'Запланирована', valueToPass: 'planned' },
          { valueToShow: 'Завершена', valueToPass: 'finished' },
        ],
        component: 'RadioFilter',
        purpose: 'status',
      },
      group: {
        title: 'По содержимому',
        filters: {
          clientsList: {
            title: 'По клиенту',
            component: 'ListFilterSelection',
            placeholder: 'Выберите клиентов',
            modal: {
              component: 'ListFilter',
              title: 'Клиенты',
              multiple: true,
            },
            purpose: 'customerIds',
          },
          lawsuitsList: {
            title: 'По делу',
            component: 'ListFilterSelection',
            placeholder: 'Выберите дело',
            modal: {
              component: 'ListFilter',
              title: 'Дела',
              multiple: false,
            },
            purpose: 'lawsuitIds',
          },
          select: {
            title: 'По типу события',
            options: 'categories',
            placeholder: 'Выберите тип события',
            component: 'SelectElement',
            multiple: true,
            closeOnSelect: false,
            purpose: 'lawsuitEventCategoryIds',
          },
          radio: {
            title: 'По важности',
            options: [
              { valueToShow: 'Все', valueToPass: null },
              { valueToShow: 'Обычное', valueToPass: false },
              { valueToShow: 'Важное', valueToPass: true },
            ],
            component: 'RadioFilter',
            purpose: 'isImportant',
          },
        },
        component: 'SearchFiltersGroup',
      },
    },
    values: {
      status: null,
      lawsuitEventCategoryIds: [],
      customerIds: [],
      lawsuitIds: [],
      isImportant: null,
    },
  },
}
