import { defineComponent, computed, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import AsidePanel from '@/components/AsidePanel/AsidePanel.vue'
import NavBar from '@/components/NavBar/NavBar.vue'
import CreateWidget from '@/components/CreateWidget/CreateWidget.vue'
import NotesPanel from '@/components/NotesPanel/NotesPanel.vue'
import TransactionPanel from '@/components/TransactionPanel/TransactionPanel.vue'
import LawyerPanel from '@/components/LawyerPanel/LawyerPanel.vue'
import { useMainStore } from '@/store/main'
import { useUXUIStore } from '@/store/uxui'
import { useFinanceStore } from '@/store/finance'
import { useLawyersStore } from '@/store/lawyers'
import { useTariffsStore } from '@/store/tariffs'
import { useUserStore } from '@/store/user'
import ModalLayer from '@/components/ModalLayer/ModalLayer.vue'
import ModalHeader from '@/components/ModalHeader/ModalHeader.vue'
import { useNotificationStore } from '@/store/notification'
import NotificationPanel from '@/components/NotificationPanel/NotificationPanel.vue'
import EventPanel from '@/components/EventPanel/EventPanel.vue'
import TariffPanel from '@/components/TariffPanel/TariffPanel.vue'
import CalendarViewsMenu from '@/components/CalendarViewsMenu/CalendarViewsMenu.vue'
import TariffSelectPanel from '@/components/TariffSelectPanel/TariffSelectPanel.vue'

export default defineComponent({
  name: 'MainLayout',
  components: {
    AsidePanel,
    NavBar,
    CreateWidget,
    NotesPanel,
    TransactionPanel,
    ModalLayer,
    ModalHeader,
    NotificationPanel,
    LawyerPanel,
    CalendarViewsMenu,
    EventPanel,
  },
  setup() {
    const route = useRoute()
    const uxuiStore = useUXUIStore()
    const mainStore = useMainStore()
    const notificationStore = useNotificationStore()
    const financeStore = useFinanceStore()
    const lawyersStore = useLawyersStore()
    const tariffsStore = useTariffsStore()
    const isCalendarPage = computed(() => route.name === 'calendar')
    const userStore = useUserStore()

    const isNotificationOpen = computed(() => uxuiStore.isNotificationOpen)
    const notification = computed(() => uxuiStore.notification)
    const isNotesPanelVisible = computed(() => uxuiStore.notesPanelVisible)
    const isTransactionPanelVisible = computed(
      () => financeStore.isTransactionPanelOpen,
    )
    const isLawyerPanelVisible = computed(() => lawyersStore.isPanelOpen)
    const isTariffPanelVisible = computed(() => tariffsStore.isPanelOpen)
    const isTariffSelectionPanelVisible = computed(
      () => userStore.isTariffPanelOpen,
    )
    const isMobile = computed(() => mainStore.isMobile)

    const router = useRouter()

    const isCreateWidgetShown = computed(
      () => !('isCreateWidgetShown' in router.currentRoute.value.meta),
    )

    router.beforeEach((to) => {
      uxuiStore.setCurrentPage((to.meta.title as string) ?? '')
    })

    const panelRegistry = {
      notes: { component: NotesPanel, visible: isNotesPanelVisible },
      transaction: {
        component: TransactionPanel,
        visible: isTransactionPanelVisible,
      },
      lawyer: { component: LawyerPanel, visible: isLawyerPanelVisible },
      tariff: { component: TariffPanel, visible: isTariffPanelVisible },
      tariffSelection: {
        component: TariffSelectPanel,
        visible: isTariffSelectionPanelVisible,
      },
    }

    const currentPanel = computed(() => {
      const visiblePanels = Object.values(panelRegistry).filter(
        (panel) => panel.visible.value,
      )
      return visiblePanels[0]?.component
    })

    onBeforeMount(async () => {
      await notificationStore.getNotificationList()
      uxuiStore.setCurrentPage(router.currentRoute.value.meta.title as string)
    })

    return {
      currentPanel,
      uxuiStore,
      ModalLayer,
      ModalHeader,
      isNotificationOpen,
      isCreateWidgetShown,
      notification,
      isMobile,
      notificationStore,
      isCalendarPage,
    }
  },
})
