<template>
  <AppPanel @close="handleClose">
    <div class="notes__panel-header">
      <h2 class="notes__panel-title">Уведомления</h2>
      <button class="notes__panel-close" @click="handleClose">
        <SvgIcon icon="x" />
      </button>
    </div>
    <div class="notes__panel-items-wrapper">
      <h3
        class="notes__panel-empty-message"
        v-if="!notificationStore.notificationList.length && !isLoading"
      >
        Список уведомлений пуст.
      </h3>
      <ul v-else class="notes__panel-items">
        <li
          v-for="item in notificationStore.notificationList"
          :key="item.id"
          class="notes__panel-item"
          @click="openLawsuit(item.type, item.data.modelId)"
        >
          <span class="notes__panel-date">{{
            getFormatDate(item.updatedAt, true)
          }}</span>
          <div class="notes__panel-content-wrapper">
            <p class="notes__panel-content">{{ item.data.text }}</p>
            <button class="notes__panel-delete">
              <SvgIcon icon="trash" @click.stop="handleDelete(item.id)" />
            </button>
          </div>
        </li>
      </ul>
      <div
        ref="notificationLoader"
        :class="['notes__panel-loader', { active: isLoading }]"
      />
    </div>
  </AppPanel>
</template>

<script lang="ts" src="./NotificationPanel.ts"></script>
<style lang="scss" scoped src="./NotificationPanel.scss"></style>
