import axios from 'axios'
import { ApplicationSuccessResponse } from '@/types/application'

const applicationApiCall = async (
  abortController?: AbortController,
): Promise<ApplicationSuccessResponse> => {
  const response = await axios.get(`/v1/application`, {
    signal: abortController?.signal,
  })
  return response.data
}

export { applicationApiCall }
