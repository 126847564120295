import { defineComponent, ref, computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import { Position } from '@/types/createWidget'
import { menuItems } from '@/config/createWidgetConfig'
import { useAuthStore } from '@/store/auth'
import { useUXUIStore } from '@/store/uxui'
import { useLawsuitStore } from '@/store/lawsuite'
import { useTasksStore } from '@/store/tasks'
import { LawsuitEmptyObject } from '@/config/lawsuitTableHeadConfig'
import { useNotesStore } from '@/store/notes'
import { useFinanceStore } from '@/store/finance'
import { useEventsStore } from '@/store/events'

export default defineComponent({
  name: 'CreateWidget',
  setup() {
    const authStore = useAuthStore()
    const uxuiStore = useUXUIStore()
    const lawsuitStore = useLawsuitStore()
    const tasksStore = useTasksStore()
    const eventsStore = useEventsStore()
    const menuOpen = ref<boolean>(false)
    const position = ref<Position>({ x: 52, y: 32 })
    const dragging = ref<boolean>(false)
    const offset = ref<Position>({ x: 52, y: 32 })
    const notesStore = useNotesStore()
    const financeStore = useFinanceStore()
    const router = useRouter()

    const user = computed(() => authStore.user)

    const createMenuItems = computed(() => {
      const currentRoute = router.currentRoute.value
      return menuItems.filter(
        (item) =>
          (user.value?.roles.includes('super admin') ||
            item.routes?.some((route) => route === currentRoute.name)) &&
          (!item.permission ||
            user.value?.permissions.includes(item.permission)),
      )
    })

    const startDrag = (event: MouseEvent): void => {
      dragging.value = true
      offset.value = {
        x: -(event.clientX + position.value.x),
        y: -(event.clientY + position.value.y),
      }
      document.addEventListener('mousemove', onDrag)
      document.addEventListener('mouseup', stopDrag)
    }

    const onDrag = (event: MouseEvent): void => {
      if (dragging.value) {
        position.value = {
          x: -(event.clientX + offset.value.x),
          y: -(event.clientY + offset.value.y),
        }
      }
    }

    const stopDrag = () => {
      dragging.value = false
      document.removeEventListener('mousemove', onDrag)
      document.removeEventListener('mouseup', stopDrag)
      menuOpen.value = true
    }

    const createLawsuit = () => {
      lawsuitStore.setSelectedLawsuitDataObject({ ...LawsuitEmptyObject })
      lawsuitStore.setSelectedLawsuit(null)
      uxuiStore.setModalName('EditLawsuit', 8)
    }

    const createClient = () => {
      uxuiStore.setModalName('ClientForm', 5)
    }

    const createTask = () => {
      tasksStore.createTaskFormOpen()
    }

    const createEvent = () => {
      eventsStore.createEventFormOpen()
    }

    const createNote = () => {
      notesStore.createNoteFormOpen()
    }

    const createTransaction = () => {
      financeStore.openCreateModal()
    }

    const createTariff = () => {
      uxuiStore.setModalName('TariffForm', 13)
    }

    watch(
      [dragging, position],
      ([newParam1, newParam2], [oldParam1, oldParam2]) => {
        if (
          newParam1 &&
          JSON.stringify(oldParam2) != JSON.stringify(newParam2)
        ) {
          console.log(oldParam1)
          menuOpen.value = false
        }
      },
      { deep: true },
    )

    return {
      menuOpen,
      startDrag,
      onDrag,
      stopDrag,
      position,
      createMenuItems,
      dragging,
      createLawsuit,
      createClient,
      createTask,
      createNote,
      createTransaction,
      createTariff,
      createEvent,
    }
  },
})
