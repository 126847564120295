import { defineComponent, ref, onBeforeMount, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import { useUXUIStore } from '@/store/uxui'
import { getFormatDate } from '@/helpers/dateFormatter'
import { useNotificationStore } from '@/store/notification'
import { useInfinityScroll } from '@/composables/useInfinityScroll'
import { modalsContent } from '@/config/confirmationModals'
import { useCalendarStore } from '@/store/calendar'

export default defineComponent({
  name: 'NotificationPanel',
  setup() {
    const router = useRouter()
    const notificationStore = useNotificationStore()
    const uxuiStore = useUXUIStore()
    const calendarStore = useCalendarStore()
    const isLoading = ref(false)
    const handleClose = () => {
      uxuiStore.switchNotificationPanel()
    }
    const notificationLoader = ref<HTMLElement | null>(null)

    const getNotification = async () => {
      isLoading.value = true
      try {
        await notificationStore.getNotificationList()
      } catch (e) {
        console.error(e)
      }
      isLoading.value = false
    }

    useInfinityScroll(isLoading, notificationLoader, getNotification)

    const handleDelete = async (id: string) => {
      // await notificationStore.notificationDelete(id)
      uxuiStore.setModalName('ConfirmationModal')
      uxuiStore.setModalContent(modalsContent['notification'], id)
    }

    const closeOnEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleClose()
      }
    }

    const openLawsuit = async (type: string, id: number) => {
      if (
        type === 'App\\Notifications\\LawsuitPreExpiredNotification' ||
        type === 'App\\Notifications\\LawsuitNotification'
      ) {
        await router.push(`/lawsuit/${id}`)
      }
      if (type === 'App\\Notifications\\LawsuitEventNotification') {
        await calendarStore.openEvent(id)
      }
    }

    onBeforeMount(async () => {
      await getNotification()
      window.addEventListener('keydown', closeOnEsc)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('keydown', closeOnEsc)
    })

    return {
      handleClose,
      notificationStore,
      getFormatDate,
      isLoading,
      notificationLoader,
      handleDelete,
      openLawsuit,
    }
  },
})
