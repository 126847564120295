<template>
  <AppPanel class="tariff-select">
    <div class="tariff-select__header">
      <h3 v-if="userSubscription && userSubscription.status === 'active'">
        Тариф
      </h3>
      <SvgIcon icon="x" @click="onClose" />
    </div>
    <div class="tariff-select__body">
      <div
        v-if="userSubscription && userSubscription.status === 'active'"
        class="tariff-select__body-item"
      >
        <div>
          <h5>
            {{ userSubscription?.tariff?.label }}
          </h5>
          <p v-if="userSubscription?.tariff?.comment">
            {{ userSubscription?.tariff?.comment }}
          </p>
        </div>
        <div>
          <span>Доступные разделы</span>
          <ol
            v-if="
              userSubscription.permissionsArr &&
              userSubscription.permissionsArr.length
            "
            class="tariff-select__body-permissions"
          >
            <li v-for="item of userSubscription.permissionsArr" :key="item">
              {{ item }}
            </li>
          </ol>
          <p v-else class="tariff-select__empty-message">Отсутствуют</p>
        </div>
      </div>
      <div>
        <h4>Доступные тарифы</h4>
        <ul class="tariff-select__body-list">
          <li
            v-for="tariff of tariffs"
            :key="tariff.id"
            class="tariff-select__body-item"
          >
            <div>
              <h5>{{ tariff.label }}</h5>
              <p v-if="tariff.comment">{{ tariff.comment }}</p>
            </div>
            <div>
              <span>Доступные разделы</span>
              <ol
                v-if="tariff.permissions.length"
                class="tariff-select__body-permissions"
              >
                <li v-for="item of tariff.permissions" :key="item">
                  {{ item }}
                </li>
              </ol>
              <p v-else class="tariff-select__empty-message">Отсутствуют</p>
            </div>
            <button
              v-if="tariff.id !== userSubscription?.tariff?.id"
              type="button"
              class="tariff-select__body-select-btn"
              @click="onChangeTariff(tariff.id)"
            >
              Выбрать
            </button>
          </li>
        </ul>
        <div v-show="isLoading" class="tariff-select__body-loader"></div>
      </div>
    </div>
  </AppPanel>
</template>

<script lang="ts" src="./TariffSelectPanel.ts"></script>

<style scoped lang="scss" src="./TariffSelectPanel.scss"></style>
