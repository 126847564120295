import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useApiCall } from '@/composables/useApiCall'
import { DefaultError } from '@/types/httpError'
import {
  notesApiCall,
  deleteNoteApiCall,
  quickNotesApiCall,
  createNoteApiCall,
  changeNoteApiCall,
} from '@/api/notes'
import {
  Note,
  NoteFormSuccessResponse,
  NotesChangePayload,
  NotesCreatePayload,
  NotesDeletePayload,
  NotesFormPayload,
  NotesFormSuccessResponse,
  QuickNotesFormPayload,
  QuickNotesFormSuccessResponse,
} from '@/types/notes'
import { useUXUIStore } from '@/store/uxui'

export const useNotesStore = defineStore('notes', () => {
  const uxuiStore = useUXUIStore()
  const notesList = ref<NotesFormSuccessResponse['data']>([])
  const selectedNote = ref<null | Note>(null)
  const errorFields = ref<null | DefaultError['error']>(null)
  const selectedLawsuitId = ref<number | null>(null)
  const selectedLawsuitCustomerId = ref<number | null>(null)
  const selectedLawsuitInfo = ref<{
    hintText: string
    customer: string
  } | null>(null)
  const statusResponse = ref(false)
  const quickNotes = ref<QuickNotesFormSuccessResponse['data']>([])
  const pageQuickNotes = ref(1)
  const {
    data: noteData,
    executeApiCall: saveData,
    error: noteError,
    isLoading: isNoteDataLoading,
  } = useApiCall<NotesFormSuccessResponse, DefaultError, NotesFormPayload>(
    notesApiCall,
    true,
  )

  const {
    data: quickNotesData,
    executeApiCall: getQuickNotesData,
    // error: quickNotesError,
    // isLoading: isQuickNotesLoading,
  } = useApiCall<
    QuickNotesFormSuccessResponse,
    DefaultError,
    QuickNotesFormPayload
  >(quickNotesApiCall, true)

  const writeData = () => {
    if (quickNotesData.value) {
      quickNotes.value = [...quickNotes.value, ...quickNotesData.value.data]
    }
  }

  const getQuickNotes = async () => {
    if (quickNotesData.value) {
      const { meta } = quickNotesData.value
      if (quickNotes.value?.length < meta.total) {
        pageQuickNotes.value++
        await getQuickNotesData({ page: pageQuickNotes.value })
        writeData()
      }
    } else {
      await getQuickNotesData({ page: pageQuickNotes.value })
      writeData()
    }
  }

  const {
    data: noteDeleteData,
    executeApiCall: deleteData,
    error: noteDeleteError,
    isLoading: isDeleteLoading,
  } = useApiCall<NotesFormSuccessResponse, DefaultError, NotesDeletePayload>(
    deleteNoteApiCall,
    true,
  )

  const isNoteList = computed(() => notesList.value)
  const isNoteLoading = computed(() => isNoteDataLoading.value)
  const isNoteDeleteLoading = computed(() => isDeleteLoading.value)

  const setSelectedLawsuitId = (
    payload: number | null,
    name: string,
    customer: string,
  ): void => {
    selectedLawsuitId.value = payload
    selectedLawsuitInfo.value = {
      hintText: name,
      customer,
    }
  }

  const setSelectedLawsuitCustomerId = (payload: number | null): void => {
    selectedLawsuitCustomerId.value = payload
  }

  const clearNotesList = (): void => {
    notesList.value = []
  }

  const deleteNote = (idToRemove: number): void => {
    notesList.value = notesList.value.filter(
      (item: Note) => item.id !== idToRemove,
    )
    quickNotes.value = quickNotes.value.filter(
      (item: Note) => item.id !== idToRemove,
    )
  }

  const addNoteToList = (newValue: Note): void => {
    notesList.value.push(newValue)
  }

  const getNotesList = async (payload: NotesFormPayload) => {
    try {
      await saveData(payload)
      if (noteData.value) {
        notesList.value = noteData.value.data
      }
    } catch {
      if (noteError.value?.data.error) {
        errorFields.value = noteError.value.data.error
      }
    }
  }

  const deleteNotesItem = async (payload: NotesDeletePayload) => {
    try {
      await deleteData(payload)
      if (noteDeleteData.value) {
        statusResponse.value = noteDeleteData.value.success
        if (noteDeleteData.value.success) {
          deleteNote(payload.id)
        }
      }
    } catch {
      if (noteDeleteError.value?.data.error) {
        errorFields.value = noteDeleteError.value.data.error
      }
    }
  }

  const selectNote = (note: Note) => {
    selectedNote.value = { ...note }
  }

  const {
    data: notesData,
    executeApiCall: createNewNote,
    error: createNoteError,
    // isLoading,
  } = useApiCall<NoteFormSuccessResponse, Error, NotesCreatePayload>(
    createNoteApiCall,
    true,
  )

  const {
    data: changeNotesData,
    executeApiCall: changeNoteAction,
    error: changeNoteError,
    // isLoading,
  } = useApiCall<NoteFormSuccessResponse, Error, NotesChangePayload>(
    changeNoteApiCall,
    true,
  )

  const setSelectNote = async (data: NotesCreatePayload) => {
    if (selectedNote.value?.id) {
      try {
        await changeNoteAction({ id: selectedNote.value.id, data })
        if (changeNotesData.value?.data) {
          if (data.customerId || data.lawsuitId) {
            quickNotes.value = quickNotes.value.filter(
              (item) => item.id !== selectedNote.value?.id,
            )
            uxuiStore.openNotification('done', 'Заметка перенесена в дело')
          } else {
            const findIndex = quickNotes.value.findIndex(
              (item) => item.id === selectedNote.value?.id,
            )
            if (findIndex !== -1) {
              quickNotes.value[findIndex] = changeNotesData.value.data
            }
            uxuiStore.openNotification('done', 'Заметка изменена')
          }
        }
        uxuiStore.setModalName('')
      } catch (e) {
        uxuiStore.openNotification(
          'error',
          changeNoteError.value?.data.message || 'Произошла ошибка',
        )
      }
    } else {
      try {
        await createNewNote(data)
        if (!data.customerId && !data.lawsuitId && notesData.value?.data) {
          quickNotes.value.unshift(notesData.value.data)
          uxuiStore.openNotification('done', 'Заметка сохранена')
        } else {
          uxuiStore.openNotification('done', 'Заметка сохранена в деле')
        }
        uxuiStore.setModalName('')
      } catch (e) {
        uxuiStore.openNotification(
          'error',
          createNoteError.value?.data.message || 'Произошла ошибка',
        )
      }
    }
  }

  const createNoteFormOpen = () => {
    uxuiStore.setModalName('EditNote', 10)
  }

  return {
    getNotesList,
    notesList,
    errorFields,
    deleteNotesItem,
    isNoteDeleteLoading,
    isNoteLoading,
    selectedLawsuitId,
    setSelectedLawsuitId,
    selectedLawsuitCustomerId,
    setSelectedLawsuitCustomerId,
    clearNotesList,
    deleteNote,
    isNoteList,
    addNoteToList,
    getQuickNotes,
    quickNotes,
    selectedNote,
    selectNote,
    setSelectNote,
    createNoteFormOpen,
    selectedLawsuitInfo,
  }
})
