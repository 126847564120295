<template>
  <div :class="['calendar-menu', { collapsed: !uxuiStore.asideCollapsed }]">
    <div class="calendar-menu__period">
      <div class="calendar-menu__period-wrapper">
        <SvgIcon
          v-if="mainStore.isMobile"
          @click="changeDate('prev')"
          icon="chevron-left"
          class="calendar-menu__period-icon"
        />
        <span v-if="!mainStore.isMobile" class="calendar-menu__period-title"
          >Период</span
        >
        <span class="calendar-menu__period-value">{{ getPeriod }}</span>
        <SvgIcon
          v-if="mainStore.isMobile"
          @click="changeDate('next')"
          icon="chevron-left"
          class="calendar-menu__period-icon --right"
        />
      </div>
      <div v-if="!mainStore.isMobile" class="calendar-menu__period-actions">
        <button
          @click="changeDate('prev')"
          class="calendar-menu__period-button"
        >
          <SvgIcon icon="chevron-left" />
        </button>
        <button
          @click="changeDate('next')"
          class="calendar-menu__period-button"
        >
          <SvgIcon icon="chevron-left" />
        </button>
      </div>
    </div>
    <div v-if="!mainStore.isMobile" class="calendar-menu__views">
      <button
        v-for="item in calendarStore.calendarViews"
        :key="item.value"
        @click="changeView(item)"
        :class="[
          'calendar-menu__views-item',
          {
            active:
              (item.active && !calendarStore.isListView) ||
              (calendarStore.isListView && item.value === 'list'),
          },
          item.title,
        ]"
      >
        <SvgIcon
          v-if="item.title === 'year'"
          :icon="`calendar-year-${item.active ? 'on' : 'off'}`"
        />
        <SvgIcon v-else :icon="`calendar-${item.title}`" />
      </button>
    </div>
  </div>
</template>

<script lang="ts" src="./CalendarViewsMenu.ts"></script>

<style lang="sass" scoped src="./CalendarViewsMenu.scss"></style>
