import { defineComponent, onMounted, PropType, ref, watch } from 'vue'
import VueDatePicker, { ModelValue } from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { isEqual, set } from 'date-fns'
import dayjs from 'dayjs'
import { DateType, UIOptions } from '@/types/datePicker'
import { useCalendarStore } from '@/store/calendar'

export default defineComponent({
  name: 'DateElement',
  components: {
    VueDatePicker,
  },
  props: {
    label: { type: String, required: false, default: '' },
    errorText: { type: Array, required: false },
    tagName: { type: String, required: false },
    value: {
      type:
        String ||
        Number ||
        (Array as PropType<ModelValue>) ||
        (Object as PropType<ModelValue>),
      required: false,
    },
    fieldName: { type: String, required: false },
    placeholder: { type: String, required: false, default: '' },
    objectKey: { type: String, required: true },
    disabled: { type: Boolean, required: false, default: false },
    yearRange: { type: Array<number>, required: false, default: [2020, 2040] },
    minDate: { type: String || null, required: false },
    maxDate: { type: Date || null, required: false },
    onlyPast: { type: Boolean, required: false, default: false },
    isCalendar: { type: Boolean, required: false, default: false },
    isLawsuit: { type: Boolean, required: false, default: false },
    isEventDisabled: { type: Boolean, required: false, default: false },
  },
  emits: ['dataChanged'],

  setup(props, { emit }) {
    const date = ref<ModelValue>(props.value || null)
    const originalDate = ref<DateType | null>(null)
    const calendarStore = useCalendarStore()
    const format = (date: Date) => {
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      return `${day}.${month}.${year}`
    }

    const onDatePickerValueChanged = (event: InputEvent) => {
      if (
        event.data &&
        ((event.target as HTMLInputElement).value?.length === 2 ||
          (event.target as HTMLInputElement).value?.length === 5)
      ) {
        ;(event.target as HTMLInputElement).value =
          (event.target as HTMLInputElement).value + '.'
      }
    }

    const uiOptions: Partial<UIOptions> = {
      navBtnNext: 'custom-next-button',
      navBtnPrev: ['custom-prev-button', 'extra-class'],
      calendar: 'custom-calendar',
      calendarCell: 'custom-calendar-cell',
      menu: 'custom-datepicker-menu',
      input: 'custom-input-field',
    }

    const getDayClass = (date: Date) => {
      if (
        isEqual(
          date,
          set(new Date(), {
            hours: 0,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
          }),
        )
      ) {
        return 'marked-cell'
      }
      return ''
    }

    const textInputOptions = {
      enterSubmit: true,
      format: 'dd.MM.yyyy',
    }

    const formatDate = (dateString: DateType) => {
      const date = dayjs(dateString)
      return date.format('DD.MM.YYYY')
    }

    const resetDate = () => {
      date.value = null
    }

    const isTribunal = (date: string) => {
      let color = ''
      calendarStore.transformEvents?.forEach((item) => {
        if (
          dayjs(item.start).format('DD.MM.YYYY') ===
          dayjs(date).format('DD.MM.YYYY')
        ) {
          return item.isTribunal
            ? (color = '#F03810')
            : (color = item.colorItem)
        }
      })
      return color
    }

    watch(
      () => props.disabled,
      (newDisabledValue) => {
        if (props.isEventDisabled) {
          return
        }
        if (newDisabledValue) {
          originalDate.value = date.value as string
          date.value = null
          emit('dataChanged', props.objectKey, null)
        } else if (originalDate.value) {
          date.value = originalDate.value
          originalDate.value = null
        }
      },
    )

    watch(date, (newDate) => {
      if (props.isCalendar && newDate) {
        switch (calendarStore.currentView?.title) {
          case 'week':
            emit(
              'dataChanged',
              props.objectKey,
              dayjs((newDate as DateType[])[0]).format('DD.MM.YYYY'),
            )
            return
          case 'year':
            emit(
              'dataChanged',
              props.objectKey,
              dayjs(`${newDate as string}-01-01`, 'YYYY-MM-DD').format(
                'DD.MM.YYYY',
              ),
            )
            return
          case 'month':
            emit(
              'dataChanged',
              props.objectKey,
              dayjs(
                `${(newDate as { month: number; year: number }).year}-${(newDate as { month: number; year: number }).month + 1}-01`,
                'YYYY-M-DD',
              ).format('DD.MM.YYYY'),
            )
            return
          default:
            emit('dataChanged', props.objectKey, formatDate(newDate as string))
            return
        }
      } else if (!props.disabled) {
        emit('dataChanged', props.objectKey, formatDate(newDate as string))
      }
    })

    watch(
      () => props.value,
      () => {
        if (props.value) {
          if (props.isCalendar) {
            switch (calendarStore.currentView?.title) {
              case 'month':
                date.value = {
                  month: dayjs(props.value, 'DD.MM.YYYY').month(),
                  year: dayjs(props.value, 'DD.MM.YYYY').year(),
                }
                return
              case 'year':
                date.value = dayjs(props.value, 'DD.MM.YYYY').year()
                return
              case 'week':
                date.value = [
                  dayjs(props.value, 'DD.MM.YYYY').startOf('week').format(),
                  dayjs(props.value, 'DD.MM.YYYY').endOf('week').format(),
                ]
                return
              default:
                date.value = dayjs(props.value, 'DD.MM.YYYY').format()
                return
            }
          }
          date.value = dayjs(props.value, 'DD.MM.YYYY').format()
        }
      },
    )

    onMounted(() => {
      if (props.value) {
        if (props.isLawsuit) {
          date.value = props.value
          return
        }
        date.value = dayjs(props.value, 'DD.MM.YYYY').format()
      }
    })

    return {
      date,
      format,
      uiOptions,
      getDayClass,
      resetDate,
      textInputOptions,
      isTribunal,
      onDatePickerValueChanged,
    }
  },
})
