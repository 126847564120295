import { MenuItemDropdown } from '@/types/createWidget'

export const menuItems: MenuItemDropdown[] = [
  {
    id: 1,
    title: 'Клиента',
    routes: ['clients-table'],
    function: 'createClient',
    permission: 'manage customers',
  },
  {
    id: 2,
    title: 'Дело',
    routes: ['home', 'client-details'],
    function: 'createLawsuit',
    permission: 'manage lawsuits',
  },
  {
    id: 3,
    title: 'Событие',
    routes: ['lawsuit-details', 'client-details', 'calendar'],
    function: 'createEvent',
    permission: 'manage calendar',
  },
  {
    id: 4,
    title: 'Задачу',
    routes: ['lawsuit-details', 'client-details', 'tasks'],
    function: 'createTask',
    permission: 'manage tasks',
  },
  {
    id: 5,
    title: 'Заметку',
    routes: ['notes'],
    function: 'createNote',
    permission: 'manage notes',
  },
  {
    id: 6,
    title: 'Транзакцию',
    routes: ['finance'],
    function: 'createTransaction',
    permission: 'manage finances',
  },
  {
    id: 7,
    title: 'Тариф',
    routes: ['adminHome'],
    function: 'createTariff',
  },
]
