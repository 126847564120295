import { defineComponent, ref, computed, watch, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/store/user'
import { useTariffsStore } from '@/store/tariffs'
import { useUXUIStore } from '@/store/uxui'
import { modalsContent } from '@/config/confirmationModals'
import { Permission } from '@/types/user'

export default defineComponent({
  name: 'TariffSelectPanel',
  setup() {
    const userStore = useUserStore()
    const tariffsStore = useTariffsStore()
    const router = useRouter()
    const uxuiStore = useUXUIStore()

    const isLoading = ref(false)

    const MANAGE_PERMISSIONS = [
      { label: 'Дела', key: 'lawsuits' },
      { label: 'Календарь', key: 'calendar' },
      { label: 'Задачи', key: 'tasks' },
      { label: 'Быстрые заметки', key: 'notes' },
      { label: 'Клиенты', key: 'customers' },
      { label: 'Финансы', key: 'finances' },
    ]

    const getPermissionsList = (permissions: Permission[]) => {
      return MANAGE_PERMISSIONS.filter((permission) =>
        permissions.some(
          (item) =>
            item.name.includes('manage') && item.name.includes(permission.key),
        ),
      ).map((item) => item.label)
    }

    const userSubscription = computed(() => {
      const prevPermissions = userStore.userSubscription
        ? userStore.userSubscription.tariff.permissions
        : []
      return {
        ...userStore.userSubscription,
        permissionsArr: getPermissionsList(prevPermissions),
      }
    })

    const tariffs = computed(() => {
      return tariffsStore.allTariffs?.map((tariff) => ({
        ...tariff,
        permissions: getPermissionsList(tariff.permissions),
      }))
    })

    const onClose = () => {
      userStore.isTariffPanelOpen = false
    }

    watch(
      () => router.currentRoute.value.name,
      () => {
        onClose()
      },
    )

    const onChangeTariff = (id: number) => {
      uxuiStore.setModalName('ConfirmationModal')
      uxuiStore.setModalContent(modalsContent['userTariff'], id)
    }

    onBeforeMount(async () => {
      isLoading.value = true
      await userStore.getUserSubscription()
      await tariffsStore.getUserTariffs()
      isLoading.value = false
    })
    return {
      userSubscription,
      tariffs,
      isLoading,
      getPermissionsList,
      onClose,
      onChangeTariff,
    }
  },
})
