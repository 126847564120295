<template>
  <form
    novalidate
    @submit.prevent="submitHandler"
    :class="['trans-form', { 'one-column': oneColumn }]"
  >
    <div :class="['trans-form__wrapper', { 'one-column': oneColumn }]">
      <fieldset :class="['trans-form__date', { 'one-column': oneColumn }]">
        <div class="trans-form__date-wrapper">
          <span class="label">Дата платежа<span class="required">*</span></span>
          <DateElement
            class="task-form__middle-time-picker"
            v-model:value="fields.date"
            object-key="date"
            placeholder="Дата платежа"
            :error-text="validationErrors?.date"
            @dataChanged="onValueChange"
          />
        </div>
        <div class="trans-form__date-wrapper">
          <span class="label"
            >Время платежа<span class="required">*</span></span
          >
          <FormElement
            class="task-form__middle-time-hours"
            tagName="input"
            placeholder="00:00"
            v-model:value="fields.time"
            :maxLength="5"
            v-maska
            data-maska="A#:C#"
            data-maska-tokens="A:[0-2]|C:[0-5]"
          />
        </div>
      </fieldset>
      <fieldset :class="['trans-form__money', { 'one-column': oneColumn }]">
        <div class="trans-form__money-wrapper">
          <span class="label"
            >Тип транзакции<span class="required">*</span></span
          >
          <div>
            <label
              v-for="type of TYPES"
              :key="type.value"
              :class="[
                'trans-form__money-input',
                { active: fields.type === type.value },
              ]"
            >
              <input
                type="radio"
                :value="type.value"
                @click="onValueChange('type', type.value)"
              />
              {{ type.text }}
            </label>
          </div>
        </div>
        <div class="trans-form__money-wrapper">
          <span
            v-if="
              !oneColumn && !isTabletOrMobile.tablet && !isTabletOrMobile.mobile
            "
            class="label"
            >Сумма<span class="required">*</span></span
          >
          <FormElement
            v-model:value="fields.amount"
            tagName="input"
            placeholder="Сумма, руб"
            required
            v-maska
            data-maska-number-locale="ru"
            data-maska-number-fraction="2"
            data-maska-number-unsigned
            :error-text="validationErrors?.amount"
          />
        </div>
      </fieldset>
    </div>
    <fieldset :class="['trans-form__select', { 'one-column': oneColumn }]">
      <div class="trans-form__select-wrapper">
        <span class="label">Клиент</span>
        <SelectElement
          :value="selectInitialValues.client"
          :options="selectClientOptions"
          :allowEmpty="true"
          placeholder="Выберите клиента"
          objectKey="customer"
          class="trans-form__select-client"
          @dataChanged="onValueChange"
        />
        <RouterLink
          :to="`/clients/${fields.customer}`"
          v-if="fields.customer"
          class="trans-form__select-link"
          >Перейти в профиль клиента
          <SvgIcon icon="chevron-right" />
        </RouterLink>
      </div>
      <div class="trans-form__select-wrapper">
        <span class="label">Дело</span>
        <SelectElement
          :value="selectInitialValues.lawsuit"
          :options="selectLawsuitOptions"
          :allowEmpty="true"
          placeholder="Выберите дело"
          objectKey="lawsuit"
          class="trans-form__select-lawsuit"
          @dataChanged="onValueChange"
        />
      </div>
    </fieldset>
    <FormElement
      tagName="textarea"
      title="Примечание"
      v-model:value.trim="fields.comment"
      placeholder="Введите примечание"
      :error-text="validationErrors?.comment"
    />
    <button
      type="submit"
      :class="[
        'trans-form__submit-btn',
        {
          'trans-form__submit-btn_full-width':
            oneColumn || isTabletOrMobile.mobile,
        },
      ]"
      :disabled="isSubmitBtnDisabled"
    >
      {{ isTransactionNew ? 'Создать' : 'Сохранить' }}
    </button>
  </form>
</template>

<script lang="ts" src="./TransactionForm.ts"></script>

<style lang="scss" src="./TransactionForm.scss"></style>
