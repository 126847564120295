import axios from 'axios'
import {
  EventChangeForm,
  EventChangeFormResponse,
  EventPartialChangeForm,
  EventsTypesResponse,
  EventCategory,
  EventsTypesChangeResponse,
  EventSearchPayload,
  EventSearchData,
  EventCreatePayload,
  EventChangePayload,
  EventByIdPayload,
  EventByIdResponse,
} from '@/types/events'

const eventChangeStatusApiCall = async (
  abortController?: AbortController,
  params?: Partial<EventChangeForm>,
): Promise<EventChangeFormResponse> => {
  const response = await axios.post(
    `/v1/lawsuit-event/${params?.id}/status`,
    { ...params },
    {
      signal: abortController?.signal,
    },
  )
  return response.data
}

const eventPartialChangeApiCall = async (
  abortController?: AbortController,
  params?: Partial<EventPartialChangeForm>,
): Promise<EventChangeFormResponse> => {
  const response = await axios.patch(
    `/v1/lawsuit-event/${params?.id}/partial-update`,
    { ...params },
    {
      signal: abortController?.signal,
    },
  )
  return response.data
}

const eventUnbindTaskApiCall = async (
  abortController?: AbortController,
  params?: Partial<EventPartialChangeForm>,
): Promise<EventChangeFormResponse> => {
  const response = await axios.patch(
    `/v1/lawsuit-event/${params?.id}/unbind-task`,
    { ...params },
    {
      signal: abortController?.signal,
    },
  )
  return response.data
}

const deleteEventApiCall = async (
  abortController?: AbortController,
  params?: Partial<EventPartialChangeForm>,
): Promise<EventChangeFormResponse> => {
  const response = await axios.delete(`/v1/lawsuit-event/${params?.id}`, {
    signal: abortController?.signal,
  })
  return response.data
}

const eventsTypesListApiCall = async (
  abortController?: AbortController,
  params?: Partial<EventPartialChangeForm>,
): Promise<EventsTypesResponse> => {
  const response = await axios.get('/v1/lawsuit-event-category', {
    params: { ...params },
    signal: abortController?.signal,
  })
  return response.data
}

const deleteEventsTypeApiCall = async (
  abortController?: AbortController,
  params?: Partial<EventCategory>,
): Promise<EventsTypesResponse> => {
  const response = await axios.delete(
    `/v1/lawsuit-event-category/${params?.id}`,
    {
      signal: abortController?.signal,
    },
  )
  return response.data
}

const eventsTypeChangeApiCall = async (
  abortController?: AbortController,
  params?: Partial<EventCategory>,
): Promise<EventsTypesChangeResponse> => {
  const response = await axios.put(
    `/v1/lawsuit-event-category/${params?.id}`,
    { ...params },
    { signal: abortController?.signal },
  )
  return response.data
}

const eventsTypeCreateApiCall = async (
  abortController?: AbortController,
  params?: Partial<EventCategory>,
): Promise<EventsTypesChangeResponse> => {
  const response = await axios.post(
    '/v1/lawsuit-event-category',
    { ...params },
    {
      signal: abortController?.signal,
    },
  )
  return response.data
}

const getEventsApiCall = async (
  abortController?: AbortController,
  params?: Partial<EventSearchPayload>,
): Promise<EventSearchData> => {
  const response = await axios.post(
    '/v1/lawsuit-event/search',
    { ...params },
    {
      signal: abortController?.signal,
    },
  )
  return response.data
}

const createEventApiCall = async (
  abortController?: AbortController,
  params?: Partial<EventCreatePayload>,
): Promise<EventByIdResponse> => {
  const response = await axios.post(
    '/v1/lawsuit-event',
    { ...params },
    {
      signal: abortController?.signal,
    },
  )
  return response.data
}

const addTaskEventApiCall = async (
  abortController?: AbortController,
  params?: Partial<EventCreatePayload>,
): Promise<EventByIdResponse> => {
  const response = await axios.post(
    `/v1/task/${params?.id}/lawsuit-event`,
    { ...params },
    {
      signal: abortController?.signal,
    },
  )
  return response.data
}

const changeEventApiCall = async (
  abortController?: AbortController,
  params?: Partial<EventChangePayload>,
): Promise<EventByIdResponse> => {
  const response = await axios.put(
    `/v1/lawsuit-event/${params?.id}`,
    { ...params },
    {
      signal: abortController?.signal,
    },
  )
  return response.data
}

const eventsByIdApiCall = async (
  abortController?: AbortController,
  params?: Partial<EventByIdPayload>,
): Promise<EventByIdResponse> => {
  const response = await axios.get(`/v1/lawsuit-event/${params?.id}`, {
    signal: abortController?.signal,
  })
  return response.data
}

export {
  eventChangeStatusApiCall,
  eventPartialChangeApiCall,
  deleteEventApiCall,
  eventsTypesListApiCall,
  deleteEventsTypeApiCall,
  eventsTypeChangeApiCall,
  eventsTypeCreateApiCall,
  getEventsApiCall,
  createEventApiCall,
  changeEventApiCall,
  eventsByIdApiCall,
  eventUnbindTaskApiCall,
  addTaskEventApiCall,
}
