import { defineComponent, ref, computed, onBeforeMount, watch } from 'vue'
import { useMainStore } from '@/store/main'
import { useLawyersStore } from '@/store/lawyers'
import { useTariffsStore } from '@/store/tariffs'
import { useApiCall } from '@/composables/useApiCall'
import { recoveryPasswordAdminApiCall } from '@/api/auth'
import { formatPhoneNumber } from '@/helpers/formatPhoneNumber'
import { formatIntMoney } from '@/helpers/formatMoney'
import { getFormatDate } from '@/helpers/dateFormatter'
import {
  MessageFormSuccessResponse,
  RecoveryPasswordPayload,
} from '@/types/auth'
import { DefaultError } from '@/types/httpError'
import { Tariff } from '@/types/user'

export default defineComponent({
  name: 'LawyersPanel',
  setup() {
    const mainStore = useMainStore()
    const lawyersStore = useLawyersStore()
    const tariffsStore = useTariffsStore()

    const lawyer = computed(() => lawyersStore.selectedLawyer)
    const tariffsOptions = computed(() => tariffsStore.selectTariffs ?? [])
    const initialTariff = computed(() => lawyer.value?.subscription?.tariff)
    const selectedTariff = ref<Tariff | null>(null)
    const isTariffChangeLoading = ref<boolean>(false)

    const {
      isLoading: isUserPasswordRecoveryLoading,
      data: userPasswordRecoveryData,
      error,
      executeApiCall: sendAdminPasswordRecovery,
    } = useApiCall<
      MessageFormSuccessResponse,
      DefaultError,
      RecoveryPasswordPayload
    >(recoveryPasswordAdminApiCall, true)

    const checkIfNotFound = (reqError: typeof error) =>
      reqError.value?.status === 404
        ? 'Данный пользователь удален или заблокирован'
        : ''

    const isMobile = computed(() => mainStore.isMobile)
    const panelError = computed(
      () =>
        checkIfNotFound(error) ||
        error.value?.data?.error?.message ||
        (lawyersStore.error && lawyersStore.error.data.message),
    )
    const onTariffSelect = (key: string, value: number) => {
      const newTariff = tariffsOptions.value.find((item) => item.id === value)
      if (newTariff) {
        selectedTariff.value = newTariff
      }
    }

    const onSendEmailLinkClick = async () => {
      if (lawyer.value?.email) {
        await sendAdminPasswordRecovery({ email: lawyer.value?.email })
      }
    }
    const onClose = () => {
      lawyersStore.closePanel()
      lawyersStore.selectedLawyer = null
      lawyersStore.error = null
    }

    const onSaveBtnClick = async () => {
      isTariffChangeLoading.value = true
      if (
        lawyer.value &&
        selectedTariff.value &&
        selectedTariff.value?.id !== initialTariff.value?.id
      ) {
        await lawyersStore.changeLawyerTariff({
          id: lawyer.value?.id,
          data: { tariffId: selectedTariff.value.id },
        })
      }
      isTariffChangeLoading.value = false
      if (!lawyersStore.error) {
        onClose()
      }
    }

    watch(
      () => lawyer.value?.id,
      () => {
        lawyersStore.error = null
      },
    )

    onBeforeMount(async () => {
      if (tariffsStore.selectTariffs === null) {
        await tariffsStore.getAllTariffs({ isPaginated: 0 })
      }
    })
    return {
      lawyer,
      tariffsOptions,
      initialTariff,
      isUserPasswordRecoveryLoading,
      isTariffChangeLoading,
      userPasswordRecoveryData,
      panelError,
      isMobile,
      formatPhoneNumber,
      formatIntMoney,
      getFormatDate,
      onTariffSelect,
      onClose,
      onSaveBtnClick,
      onSendEmailLinkClick,
    }
  },
})
