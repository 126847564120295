import axios from 'axios'
import {
  LoginFormPayload,
  MessageFormSuccessResponse,
  SMSLoginFormPayload,
  AuthFormSuccessResponse,
  RecoveryPasswordPayload,
  RegistrationPayload,
  UpdatePasswordPayload,
  UserLogoutResponse,
  RegistrationSMSPayload,
  //UserChangePhoneNumberPayload,
  //UserChangePhoneNumberSuccess,
} from '@/types/auth'

const loginApiCall = async (
  abortController?: AbortController,
  params?: Partial<LoginFormPayload>,
): Promise<MessageFormSuccessResponse> => {
  const { data } = await axios.post(
    '/v1/auth/login',
    { ...params },
    { signal: abortController?.signal },
  )
  return data
}

const loginAdminApiCall = async (
  abortController?: AbortController,
  params?: Partial<LoginFormPayload>,
): Promise<MessageFormSuccessResponse> => {
  const { data } = await axios.post(
    '/v1/admin/auth/login',
    { ...params },
    { signal: abortController?.signal },
  )
  return data
}

const registrationApiCall = async (
  abortController?: AbortController,
  params?: Partial<RegistrationPayload>,
): Promise<AuthFormSuccessResponse> => {
  const { data } = await axios.post(
    '/v1/registration',
    { ...params },
    { signal: abortController?.signal },
  )
  return data
}

const registrationSMSApiCall = async (
  abortController?: AbortController,
  params?: Partial<RegistrationSMSPayload>,
): Promise<MessageFormSuccessResponse> => {
  const { data } = await axios.post(
    '/v1/registration/code',
    { ...params },
    { signal: abortController?.signal },
  )
  return data
}

const registrationCodeApiCall = async (
  abortController?: AbortController,
  params?: Partial<SMSLoginFormPayload>,
): Promise<AuthFormSuccessResponse> => {
  const { data } = await axios.post(
    '/v1/user/activate',
    { ...params },
    { signal: abortController?.signal },
  )
  return data
}

const registrationEmailCheckApiCall = async (
  abortController?: AbortController,
  params?: Omit<SMSLoginFormPayload, 'code'>,
) => {
  const { data } = await axios.post(
    '/v1/registration/email',
    { ...params },
    { signal: abortController?.signal },
  )
  return data
}

// const changePhoneNumberDuringRegistrationApiCall = async (
//   abortController?: AbortController,
//   params?: UserChangePhoneNumberPayload,
// ): Promise<UserChangePhoneNumberSuccess> => {
//   const { data } = await axios.post(
//     '/v1/change-phone',
//     { ...params },
//     { signal: abortController?.signal },
//   )
//   return data
// }

const smsLoginApiCall = async (
  abortController?: AbortController,
  params?: Partial<SMSLoginFormPayload>,
): Promise<AuthFormSuccessResponse> => {
  const { data } = await axios.post(
    '/v1/auth/login/complete',
    { ...params },
    { signal: abortController?.signal },
  )
  return data
}

const completeLoginAdmin = async (
  abortController?: AbortController,
  params?: Partial<SMSLoginFormPayload>,
): Promise<AuthFormSuccessResponse> => {
  const { data } = await axios.post(
    '/v1/admin/auth/login/complete',
    { ...params },
    { signal: abortController?.signal },
  )
  return data
}

const recoveryPasswordApiCall = async (
  abortController?: AbortController,
  params?: Partial<RecoveryPasswordPayload>,
): Promise<MessageFormSuccessResponse> => {
  const { data } = await axios.post(
    '/v1/reset-password-link',
    { ...params },
    { signal: abortController?.signal },
  )
  return data
}

const recoveryPasswordAdminApiCall = async (
  abortController?: AbortController,
  params?: Partial<RecoveryPasswordPayload>,
) => {
  const { data } = await axios.post(
    '/v1/admin/reset-password-link',
    { ...params },
    { signal: abortController?.signal },
  )
  return data
}

const updatePasswordApiCall = async (
  abortController?: AbortController,
  params?: Partial<UpdatePasswordPayload>,
): Promise<MessageFormSuccessResponse> => {
  const { data } = await axios.post(
    `/v1/reset-password?token=${params?.token}`,
    {
      ...params?.payload,
    },
    { signal: abortController?.signal },
  )
  return data
}

const logoutApiCall = async (
  abortController?: AbortController,
): Promise<UserLogoutResponse> => {
  const { data } = await axios.post('/v1/auth/logout', {
    signal: abortController?.signal,
  })
  return data
}

const logoutAdminApiCall = async (
  abortController?: AbortController,
): Promise<UserLogoutResponse> => {
  const { data } = await axios.post('/v1/admin/auth/logout', {
    signal: abortController?.signal,
  })
  return data
}

export {
  loginApiCall,
  loginAdminApiCall,
  logoutApiCall,
  logoutAdminApiCall,
  smsLoginApiCall,
  completeLoginAdmin,
  recoveryPasswordApiCall,
  recoveryPasswordAdminApiCall,
  registrationApiCall,
  registrationCodeApiCall,
  registrationEmailCheckApiCall,
  //changePhoneNumberDuringRegistrationApiCall,
  registrationSMSApiCall,
  updatePasswordApiCall,
}
